<template>
  <v-container>
    <v-btn
      v-if="!getCartItems.find((f) => f.id === item.id)"
      color="white"
      small
      depressed
      @click="() => addToCart(item)"
    >
      <v-icon class="mr-3" color="primary">mdi-plus-thick</v-icon> Add To Cart
    </v-btn>
    <div
      v-if="getCartItems.find((f) => f.id === item.id)"
      class="d-flex justify-space-between"
    >
      <v-btn icon small depressed @click="() => addToCart(item)" :disabled="warning">
        <v-icon :color="warning ? 'error' : 'primary'">mdi-plus-thick</v-icon>
      </v-btn>
      <v-chip small label :color="warning ? 'red lighten-2' : ''">
        {{ getCartItemQuantity(item.id) }}
      </v-chip>
      <v-btn icon small depressed @click="() => removeFromCart(item.id)">
        <v-icon color="error">mdi-minus-thick</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartButtons",
  props: {
    item: {
      type: Object,
      required: true,
    },
    warning: Boolean,
  },
  computed: {
    ...mapGetters("cart", ["getCartItems", "getCartItemQuantity"]),
  },
  methods: {
    ...mapActions("cart", ["addToCart", "removeFromCart"]),
  },
};
</script>
