<template>
  <full-view ref="viewFrame" stretch-toolbar fab="mdi-arrow-up" @fab:click="goTop()">
    <template v-slot:toolbar>
      <v-text-field
        v-model="search"
        hide-details
        solo
        flat
        dense
        clearable
        clear-icon="mdi-close"
        :placeholder="$t('general.search') + '...'"
        style="width: 300px"
        @click:append="() => (showSearch = !showSearch)"
      >
      </v-text-field>
    </template>

    <a id="top" />

    <v-card tile flat class="pl-3 pr-1" v-if="getStoreIdParam > 0">
      <v-row class="mb-5" no-gutters>
        <v-col
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="6"
          v-for="(item, i) in filterProducts"
          :key="i"
          class="pr-2"
        >
          <product-card
            :item="item"
            show-rating
            @click:card="() => $$router.push({ name: 'product', params: { productId: item.id } })"
          />
        </v-col>
      </v-row>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import ProductCard from "@/views/stores/ProductCard";

export default {
  name: "StoreSearch",
  components: { FullView, ProductCard },
  data() {
    return {
      search: null,
    };
  },
  mounted() {
    this.loadStoreProductsByParams({ storeId: this.getStoreIdParam });
    this.setReactivityParams({
      resource: "PRODUCTS",
      scope: "store",
      key: this.getStoreIdParam,
    });
    this.$sub("reactivity/PRODUCTS", (payload) => {
      this.loading = true;
      if (payload.mode !== "Delete") {
        this.reloadStoreProductsByParams({
          storeId: this.getStoreIdParam,
          ids: [payload.product],
        }).then(() => (this.loading = false));
      } else {
        this.removeStoreProductsFromState(payload.product).then(
          () => (this.loading = false)
        );
      }
      console.log("reactivity/PRODUCTS:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  computed: {
    ...mapGetters("products", ["getStoreProducts"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    filterProducts() {
      if (!this.search || this.search.length < 3 || !this.getStoreIdParam) {
        return null;
      }

      const words = this.search.trim().split(" ");
      const products = this.getStoreProducts;
      return this.notEmptyArray(products)
        ? products.filter((f) =>
            words.every(
              (w) =>
                f.name.toString().toLocaleLowerCase().indexOf(w.toLocaleLowerCase()) !==
                  -1 ||
                (f.description &&
                  f.description.toLocaleLowerCase().indexOf(w.toLocaleLowerCase()) !==
                    -1) ||
                (f.category &&
                  f.category.name.toLocaleLowerCase().indexOf(w.toLocaleLowerCase()) !==
                    -1) ||
                (f.category.section &&
                  f.category.section.name
                    .toLocaleLowerCase()
                    .indexOf(w.toLocaleLowerCase()) !== -1)
            )
          )
        : null;
    },
  },
  methods: {
    ...mapActions("products", [
      "loadStoreProductsByParams",
      "removeStoreProductsFromState",
      "reloadStoreProductsByParams",
    ]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),

    goTop() {
      this.$vuetify.goTo("#top", { offset: -40, container: this.$refs.viewFrame });
    }
  },
};
</script>
